import { React, useEffect, useState } from "react";
import { Button, List, ListItem, ListItemContent, Typography, Stack } from "@mui/joy/";
import axios from "axios";
import copy from "copy-text-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "react-tooltip";
import { ip } from "../../ip";
import { useNavigate } from "react-router-dom";

function About(props) {
  axios.defaults.withCredentials = true;
  const organization = props?.data[0]?.organization || props?.data[0]?.fullName || props?.data.organization || props?.data.fullName;
  const address = props?.data[0]?.addr || props?.data.addr;
  const schoolAddress = props?.data[0]?.schoolAddr || props?.data.schoolAddr;
  const date = props?.data[0]?.created || props?.data.created;
  const hub = props?.data[0]?.healthhub || props?.data.healthhub;
  const school = props?.data[0]?.school || props?.data.school;
  const id = props?.data[0]?.id || props?.data.id;
  const creator = props?.data[0]?.creator || props?.data.creator;
  const user = props?.user;
  const admin = props?.admin;
  const isAuthenticated = props?.isAuthenticated;
  const [tooltip, setTooltip] = useState("Click to copy address");
  const Navigate = useNavigate();

  return (
    <>
      <div>
        <List aria-labelledby="decorated-list-demo">
          <ListItem>
            {user && user["account_type"] === "NPO" ? (
              <Typography component={"span"}>
                <b>ID: </b>
                {id}
              </Typography>
            ) : (
              <>
                <Typography component={"span"}>
                  <b>ID: </b> {id}
                </Typography>
              </>
            )}
          </ListItem>
          {isAuthenticated ? (
            <>
              <ListItem>
                <Typography component={"span"}>
                  <b>Health Hub: </b> {hub}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography component={"span"}>
                  <b>Delivery Address: </b> {address}
                </Typography>
                <ContentCopyIcon
                  onClick={() => {
                    copy(address);
                    setTooltip("Copied!");
                    setTimeout(() => {
                      setTooltip("Click to copy address");
                    }, 1000);
                  }}
                  color="primary"
                  style={{ cursor: "pointer" }}
                  data-tooltip-id="address"
                  data-tooltip-content={tooltip}
                  data-tooltip-place="top"
                />
                <Tooltip id="address" />
              </ListItem>
              <ListItem>
                <Typography component={"span"}>
                  <b>School: </b> {school}
                </Typography>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem>
                <Typography component={"span"}>
                  <b>School: </b>
                </Typography>
                <Typography component={"span"}>{school}</Typography>
              </ListItem>
              <ListItem>
                <Typography component={"span"}>
                  <b>Delivery Address: </b>
                </Typography>
                <Typography component={"span"} color="danger">
                  Log in to view
                </Typography>
              </ListItem>
            </>
          )}
          <ListItem>
            <Typography component={"span"}>
              <b>Date: </b> {date.split("T")[0]}
            </Typography>
          </ListItem>
          {organization && isAuthenticated ? (
            <ListItem>
              <Typography component={"span"}>
                <b>Organization: </b> {organization}
              </Typography>
            </ListItem>
          ) : (
            <ListItem>
              <Typography component={"span"}>
                <b>Organization: </b>
              </Typography>
              <Typography component={"span"} color="danger">
                Log in to view
              </Typography>
            </ListItem>
          )}
          {admin === true ? (
            <ListItem>
              <Typography component={"span"}>
                <b>Created By: </b> {creator}
              </Typography>
            </ListItem>
          ) : null}
        </List>
      </div>
    </>
  );

}

export default About;
