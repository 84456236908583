import React from "react";
import Table from "@mui/joy/Table";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Stack,
  Modal,
  ModalDialog,
  ModalClose,
  Button,
  Typography,
  List,
  ListItem,
  Checkbox,
  Input,
  Autocomplete,
} from "@mui/joy";
import emailjs from "@emailjs/browser";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { ip } from "../../ip";

function Product(props) {
  axios.defaults.withCredentials = true;
  const user = props.user;
  const isAuthenticated = props.isAuthenticated;
  const setCheckedStates = props.setCheckedStates;
  const [modalOpen, setModalOpen] = useState(
    Array(props.data.length).fill(false)
  );
  const checked = props.checked;
  const askIndex = props.askIndex;
  const handleCheckBoxChange = props.handleCheckBoxChange;
  const edit = props.edit;
  const newQuantity = props.newQuantity ? props.newQuantity : [];
  const newLink = props.newLink ? props.newLink : [];
  const setNewQuantity = props.setNewQuantity ? props.setNewQuantity : () => {};
  const setNewLink = props.setNewLink ? props.setNewLink : () => {};
  const setProductNames = props.setProductNames
    ? props.setProductNames
    : () => {};
  const setNewProduct = props.setNewProduct ? props.setNewProduct : () => {};
  const tracking = props.tracking;
  const setTracking = props.setTracking;
  const [products, setProducts] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    axios.get(`${ip}/products`).then((response) => {
      setProducts(response.data);
    });
  }, []);

  useEffect(() => {
    //loop through the data and set the newQuantity state to the quantity of each item
    let temp = [];
    let tempLink = [];
    let tempProductNames = [];
    props.data.map((item) => {
      temp.push(item.quantity);
      tempLink.push(item.link);
      tempProductNames.push(item.product_name);
    });
    setNewQuantity(temp);

    //loop through the data and set the newLink state to the link of each item
    setNewLink(tempLink);

    //loop through the data and set the productNames state to the product_name of each item
    setProductNames(() => {
      const newState = [];
      props.data.map((item) => {
        newState.push(item.product_name);
      });
      return newState;
    });

    //loop through the data and set the newProduct state to the product_name of each item
    setNewProduct(() => {
      const newState = [];
      props.data.map((item) => {
        newState.push(item.product_name);
      });
      return newState;
    });
  }, [props.data]);

  function EmailNPO(
    to_email_id,
    from_email,
    from_name,
    from_organization,
    content,
    status,
    ask_id
  ) {
    axios.get(`${ip}/email/${to_email_id}`).then((response) => {
      if (response.data) {
        emailjs
          .send(
            "service_s2rydx7",
            "template_1srk5gx",
            {
              to_email: response.data.email,
              from_email: from_email,
              from_name: from_name,
              from_organization: from_organization,
              content: content,
              status: status,
              ask_id: ask_id,
            },
            "x7LM43rlkJsqov2r7"
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("email sent");
            } else {
              console.log("email not sent");
            }
          });
      }
    });
  }

  function Email(
    to_email_id,
    from_email,
    from_name,
    from_organization,
    content,
    status,
    ask_id
  ) {
    axios.get(`${ip}/email/${to_email_id}`).then((response) => {
      if (response.data) {
        emailjs
          .send(
            "service_s2rydx7",
            "template_bs16y39",
            {
              to_email: response.data.email,
              from_email: from_email,
              from_name: from_name,
              from_organization: from_organization || "N/A",
              content: content,
              status: status,
              ask_id: ask_id,
            },
            "x7LM43rlkJsqov2r7"
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("email sent");
            } else {
              console.log("email not sent");
            }
          });
      }
    });
  }

  //school side
  // function deleteAskProduct(id, product_name) {
  //   axios
  //     .post(`${ip}/asks/deleteAskProduct/${id}/${product_name}`)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         props.setStatusClick(!props.statusClick);
  //       }
  //     });
  // }

  function show_status_button(item, user, index) {
    if (user.account_type === "School") {
      if (item.acceptance_data[0] === null) {
        return (
          <Typography color="neutral">
            <b>Not Accepted</b>
          </Typography>
        );
      }
      else {
        //if the item is accepted, accepted text with link to <Ask> page
          return (
            <Stack spacing={1}>
              <Button
                color="success"
                onClick={() => {
                  Navigate(`/ask/${item.ask_id}`);
                }}
                sx={{ cursor: "pointer", width: "auto", height: "auto", alignContent: "center", maxWidth: "200px" }}
              >
                <b>See Details</b>
              </Button>
            </Stack>
          );
    //     else {
    //       if (parseInt(item.acceptance_data[0].status) === 1) {
    //         return (
    //           <Stack spacing={1}>
    //             <Typography color="success">
    //               <b>Accepted</b>
    //             </Typography>
    //           </Stack>
    //         );
    //       }
    //       //if the item is fulfilled, fulfilled text with link to <Ask> page
    //       else if (parseInt(item.acceptance_data[0].status) === 2) {
    //         return (
    //           <Typography color="success">
    //             <b>Fulfilled</b>
    //           </Typography>
    //         );
    //       }
    //       //if the item is received, received text with link to <Ask> page
    //       else if (parseInt(item.acceptance_data[0].status) === 3) {
    //         return (
    //           <Typography color="success">
    //             <b>Received</b>
    //           </Typography>
    //         );
    //       }

    //     }
    //   }
    // }
    // else {
    //   if (
    //     !item.status ||
    //     parseInt(item.status) === 0 ||
    //     item.status === null
    //   ) {
    //     return (
    //       <Typography color="neutral">
    //         <b>Not Accepted</b>
    //       </Typography>
    //     );
    //   } else if (parseInt(item.status) == 1) {
    //     if (user.account_type === "NPO" || user.account_type === "member") {
    //       return (
    //         <Stack spacing={1}>
    //           <Typography color="success">
    //             <b>Accepted</b>
    //           </Typography>
    //         </Stack>
    //       );
    //     }
    //   } else if (parseInt(item.status) == 2) {
    //     if (user.account_type === "NPO" || user.account_type === "member") {
    //       return (
    //         <Typography color="success">
    //           <b>Awaiting School Confirmation</b>
    //         </Typography>
    //       );
    //     }
    //   } else if (parseInt(item.status) === 3) {
    //       return (
    //         <Typography color="success">
    //           <b>Completed</b>
    //         </Typography>
    //       );
        }
    }
    else {
      if (item.status && item?.status !== 0 && item.accepted_id !== null) {
        if (parseInt(item.status) === 1) {
          return (
            <Typography color="success">
              <b>Accepted</b>
            </Typography>
          );
        } else if (parseInt(item.status) === 2) {
          return (
            <Typography color="success">
              <b>Awaiting School Confirmation</b>
            </Typography>
          );
        } else if (parseInt(item.status) === 3) {
          return (
            <Typography color="success">
              <b>Completed</b>
            </Typography>
          );
        }
      } else {
        return (
          <Typography color="danger">
            <b>Not Accepted</b>
          </Typography>
        );
      }
    }
  }

  function show_error_empty_quantity(product_index) {
    //check if the requested_quantity is empty
    if (
      checked[product_index]?.id != null &&
      (!checked[product_index]?.requested_quantity ||
        checked[product_index]?.requested_quantity === undefined ||
        checked[product_index]?.requested_quantity === null ||
        isNaN(checked[product_index]?.requested_quantity) ||
        checked[product_index]?.requested_quantity < 1 ||
        checked[product_index]?.requested_quantity > checked[product_index]?.remaining_quantity)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function show_contact_button(item, user, index) {
    if (user.account_type === "School") {
      // if (item.acceptance_data[0] === null) {
      //   return (
      //     <Button size="sm" disabled>
      //       N/A
      //     </Button>
      //   );
      // }
      // else {
      //   return (
      //     <>
      //       <Button
      //         variant="outlined"
      //         color="neutral"
      //         onClick={() => setModalOpen(true)}
      //       >
      //         Show
      //       </Button>
      //       <Modal
      //         aria-labelledby="modal-title"
      //         aria-describedby="modal-desc"
      //         open={modalOpen}
      //         onClose={() => setModalOpen(false)}
      //         sx={{
      //           display: "flex",
      //           justifyContent: "center",
      //           alignItems: "center",
      //         }}
      //       >
      //         <ModalDialog>
      //           <ModalClose />
      //           <Typography
      //             component="h2"
      //             id="modal-title"
      //             level="h4"
      //             textColor="inherit"
      //             fontWeight="lg"
      //             mb={1}
      //           >
      //             Contact Info
      //           </Typography>
      //           <Typography id="modal-desc" textColor="text.tertiary">
      //             {item.acceptance_data.map((acceptance, i) => (
      //               <List key={i} aria-labelledby="decorated-list-demo">
      //                 <ListItem>
      //                   <b>Name: </b> {acceptance.accepted_user_name || "N/A"}
      //                 </ListItem>
      //                 <ListItem>
      //                   <b>Email: </b> {acceptance.accepted_user_email || "N/A"}
      //                 </ListItem>
      //                 <ListItem>
      //                   <b>Organization: </b>{" "}
      //                   {acceptance.accepted_user_organization || "N/A"}
      //                 </ListItem>
      //                 {acceptance.tracking ? (
      //                   <ListItem>
      //                     <b>Tracking Number: </b>{" "}
      //                     <a href={acceptance.tracking}>
      //                       {acceptance.tracking}
      //                     </a>
      //                   </ListItem>
      //                 ) : (
      //                   <ListItem>
      //                     <b>Tracking Number: </b> N/A
      //                   </ListItem>
      //                 )}
      //                 <ListItem>
      //                   <b>Quantity Accepted: </b>
      //                   {acceptance.accepted_quantity}
      //                 </ListItem>
      //               </List>
      //             ))}
      //           </Typography>
      //         </ModalDialog>
      //       </Modal>
      //     </>
      //   );
      // }
    }
    else {
      if (item.status && item?.status !== 0 && item.accepted_id !== null) {
        return (
          <>
            <Button
              variant="solid"
              color="primary"
              sx={{ width: "auto" }}
              onClick={() =>
                setModalOpen((prev) => {
                  const newState = [...prev];
                  newState[index] = true;
                  return newState;
                })
              }
            >
              Show
            </Button>
            <Modal
              aria-labelledby="modal-title"
              aria-describedby="modal-desc"
              open={modalOpen[index]}
              onClose={() =>
                setModalOpen((prev) => {
                  const newState = [...prev];
                  newState[index] = false;
                  return newState;
                })
              }
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ModalDialog>
                <ModalClose />
                <Typography
                  component="h2"
                  id="modal-title"
                  level="h4"
                  textColor="inherit"
                  fontWeight="lg"
                  mb={1}
                >
                  Contact Info
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary">
                  {user.account_type === "School" && item.accepted_user_name ? (
                    <List aria-labelledby="decorated-list-demo">
                      <ListItem>
                        <b>Name: </b> {item?.accepted_user_name || "N/A"}
                      </ListItem>
                      <ListItem>
                        <b>Email: </b> {item?.accepted_user_email || "N/A"}
                      </ListItem>
                      <ListItem>
                        <b>Organization: </b>{" "}
                        {item?.accepted_user_organization || "N/A"}
                      </ListItem>
                      {item.tracking ? (
                        <ListItem>
                          <b>Tracking Number: </b>{" "}
                          {<a href={item.tracking}>{item.tracking}</a> || "N/A"}
                        </ListItem>
                      ) : (
                        <ListItem>
                          <b>Tracking Number: </b> N/A
                        </ListItem>
                      )}
                      <ListItem>
                        <b>Quantity Accepted:</b>
                        {item.accepted_quantity}
                      </ListItem>
                    </List>
                  ) : item.created_user ? (
                    <List aria-labelledby="decorated-list-demo">
                      <ListItem>
                        <b>Name: </b> {item?.created_user?.fullName || "N/A"}
                      </ListItem>
                      <ListItem>
                        <b>Email: </b> {item?.created_user?.email || "N/A"}
                      </ListItem>
                      <ListItem>
                        <b>Organization: </b>{" "}
                        {item?.created_user?.organization || "N/A"}
                      </ListItem>
                    </List>
                  ) : null}
                </Typography>
              </ModalDialog>
            </Modal>
          </>
        );
      } else if (item.status === 3 && item.accepted_id === null) {
        //anonymous doner
        return (
          <>
            <Button
              variant="outlined"
              sx={{ width: "auto" }}
              color="neutral"
              onClick={() =>
                setModalOpen((prev) => {
                  const newState = [...prev];
                  newState[index] = true;
                  return newState;
                })
              }
            >
              Show
            </Button>
            <Modal
              aria-labelledby="modal-title"
              aria-describedby="modal-desc"
              open={modalOpen[index]}
              onClose={() =>
                setModalOpen((prev) => {
                  const newState = [...prev];
                  newState[index] = false;
                  return newState;
                })
              }
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ModalDialog>
                <ModalClose />
                <Typography
                  component="h2"
                  id="modal-title"
                  level="h4"
                  textColor="inherit"
                  fontWeight="lg"
                  mb={1}
                >
                  Contact Info
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary">
                  Anonymous Doner
                </Typography>
              </ModalDialog>
            </Modal>
          </>
        );
      } else {
        return (
          <Button size="sm" disabled sx={{ width: "auto" }}>
            N/A
          </Button>
        );
      }
    }
    
  }

  return (
    <Table aria-label="basic table" variant="outlined" hoverRow>
      <thead>
        <tr>
          <th style={{ width: "40%" }}>Item</th>
          <th>Quantity</th>
          <th>Link to Product</th>
          <th>Status</th>
          {user.account_type === "NPO" ? <th>Contact</th> : null}
          {props?.fulfilled ? <th>Tracking Info</th> : null}
        </tr>
      </thead>
      <tbody>
        {props.data.map((item, index) => (
          <tr key={index}>
            {!isAuthenticated ||
            (user.account_type === "School" && item?.acceptance_data[0]) ||
            ((user.account_type === "NPO" || user.account_type === "member") &&
              item.status === 3) ? (
              <td>
                <Typography component={"span"} size="md">
                  {item.product_name}
                </Typography>
              </td>
            ) : (
              <>
                {edit ? (
                  <td>
                    <Autocomplete
                      options={Object.keys(products).map(
                        (productId) => products[productId].productName
                      )}
                      onChange={(e, value) =>
                        setNewProduct((prev) => {
                          const newState = [...prev];
                          newState[index] = value;
                          return newState;
                        })
                      }
                      renderInput={(params) => <input {...params} />}
                      size="md"
                      sx={{ minWidth: 175 }}
                      placeholder="Select an item"
                    />
                  </td>
                ) : (
                  <td>
                    <Checkbox
                      color="primary"
                      size="sm"
                      label={item.product_name}
                      checked={
                        checked && checked[index] && checked[index].id !== null
                          ? checked[index]
                          : false
                      }
                      onChange={(e) => {
                        handleCheckBoxChange(
                          e,
                          askIndex,
                          index,
                          item,
                          item.quantity,
                          item.status,
                          item.ask_id
                        );
                      }}
                    />
                  </td>
                )}
              </>
            )}
            {edit ? (
              <td>
                <Input
                  type="text"
                  value={newQuantity[index]}
                  onChange={(e) => {
                    setNewQuantity((prev) => {
                      const newState = [...prev];
                      newState[index] = e.target.value;
                      return newState;
                    });
                  }}
                />
              </td>
            ) : (
              <td>
                {user["account_type"] === "NPO" &&
                checked &&
                checked[index] &&
                checked[index].id !== null &&
                !checked[index].status ? (
                  <Input
                    type="number"
                    placeholder={"1-" + item.remaining_quantity}
                    slotProps={{
                      input: {
                        min: 1,
                        max: item.remaining_quantity,
                        step: 1,
                      },
                    }}
                    onChange={(e) => {
                      setCheckedStates((prev) => {
                        const newState = [...prev];
                        newState[askIndex][index].requested_quantity = parseInt(
                          e.target.value
                        );
                        return newState;
                      });
                    }}
                    error={show_error_empty_quantity(index)}
                  />
                ) : user["account_type"] === "NPO" &&
                  (item["remaining_quantity"] !== item["quantity"] ||
                    item["accepted_quantity"]) ? (
                  <Typography component={"span"} size="md">
                    {item.remaining_quantity
                      ? item.remaining_quantity
                      : item.accepted_quantity}
                  </Typography>
                ) : (
                  <Typography component={"span"} size="md">
                    {item.quantity}
                  </Typography>
                )}
              </td>
            )}
            {edit ? (
              <td>
                <Input
                  type="text"
                  value={newLink[index]}
                  onChange={(e) => {
                    setNewLink((prev) => {
                      const newState = [...prev];
                      newState[index] = e.target.value;
                      return newState;
                    });
                  }}
                />
              </td>
            ) : (
              <td>
                {item.link === "" || item.link === null ? (
                  <Typography component={"span"} size="md">
                    N/A
                  </Typography>
                ) : (
                  <Button
                    variant="solid"
                    color="primary"
                    component="a"
                    href={item.link}
                    target="_blank"
                    sx={{ width: "auto" }}
                  >
                    Click Here
                  </Button>
                )}
              </td>
            )}
            <td>
              <Typography component={"span"}>
                {show_status_button(item, user, index)}
              </Typography>
            </td>
            {user.account_type === "NPO" ? (
              <td>
                <Typography component={"span"}>
                  {show_contact_button(item, user, index)}
                </Typography>
              </td>
            ) : null}
            {item?.tracking && user.account_type === "NPO" ? (
              <td>
                <Button
                  variant="solid"
                  color="primary"
                  component="a"
                  href={item.tracking}
                  target="_blank"
                  sx={{ width: "auto" }}
                >
                  Click Here
                </Button>
              </td>
            ) : props.fulfilled ? (
              <td>
                <Typography component={"span"}>
                  Click Checkbox To Add Tracking Info
                </Typography>
              </td>
            ) : null}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default Product;
