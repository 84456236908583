import * as React from "react";
import { CardCover, CardContent, Button, Box, Card, Stack } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import training_video from "../../videos/training_video.mp4";
import create_ask from "../../videos/create_ask.mp4";
import mark_fulfilled from "../../videos/mark_fulfilled.mp4";
import accept_ask from "../../videos/accept_ask.mp4";

export default function Training(props) {
  const createAskVideoRef = React.useRef(null); // Ref for the create_ask video
  const markFulfilledVideoRef = React.useRef(null); // Ref for the mark_fulfilled video
  const acceptAskVideoRef = React.useRef(null); // Ref for the accept_ask video
  const user = props.user;

  const handleVideoClick = (videoRef) => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleFullScreen = (videoRef) => {
    const video = videoRef.current;
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      video.msRequestFullscreen();
    }
  };

  return (
    <Box
      component="ul"
      sx={{
        display: "flex",
        gap: 2,
        flexWrap: "wrap",
        p: 0,
        m: 0,
        alignItems: "center", // Align items vertically center
        justifyContent: "center", // Align content horizontally center
        marginBottom: "50px",
      }}
    >
      <Stack
        display={"flex"}
        spacing={2}
        useFlexGap={true}
        sx={{
          borderBottom: "0", // Remove the border
          paddingBottom: "0 !important", // Remove padding bottom
          marginLeft: "10px",
        }}
      >
        <Stack sx={{}}>
          <Typography level="h1">Training Videos</Typography>
        </Stack>
        {user && user.account_type === "NPO" ? (
          <Card
            component="li"
            sx={{
              display: "flex",
              flexDirection: "column", // Ensure the card content stacks vertically
              minWidth: 300,
              minHeight: "flex",
              direction: "row",
              maxWidth: "75%",
            }}
          >
          <Typography level="h4">Accept Ask</Typography>
              <Card
                component="li"
                sx={{
                  display: "flex",
                  flexDirection: "column", // Ensure the card content stacks vertically
                  minWidth: 300,
                  minHeight: "flex",
                  direction: "row",
                  maxWidth: "75%",
                }}
              >
                <CardCover>
                  <video
                    ref={acceptAskVideoRef}
                    muted
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                    onClick={() => handleVideoClick(acceptAskVideoRef)}
                  >
                    <source src={accept_ask} type="video/mp4" />
                  </video>
                </CardCover>
                <CardContent>
                  <Typography
                    level="body-lg"
                    fontWeight="lg"
                    textColor="#fff"
                    mt={{ xs: 12, sm: 18 }}
                  >
                    Video
                  </Typography>
                  <Button
                    size="sm"
                    width="5px"
                    fullWidth={false}
                    onClick={() => handleFullScreen(acceptAskVideoRef)}
                  >
                    Play
                  </Button>
                </CardContent>
              </Card>
            </Card>
        ) : (
          <>
            <Card
              component="li"
              sx={{
                display: "flex",
                flexDirection: "column", // Ensure the card content stacks vertically
                minWidth: 300,
                minHeight: "flex",
                direction: "row",
                maxWidth: "75%",
              }}
            >
              <Typography level="h4">Create Ask</Typography>
              <Card
                component="li"
                sx={{
                  display: "flex",
                  flexDirection: "column", // Ensure the card content stacks vertically
                  minWidth: 300,
                  minHeight: "flex",
                  direction: "row",
                  maxWidth: "75%",
                }}
              >
                <CardCover>
                  <video
                    ref={createAskVideoRef}
                    muted
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                    onClick={() => handleVideoClick(createAskVideoRef)}
                  >
                    <source src={create_ask} type="video/mp4" />
                  </video>
                </CardCover>
                <CardContent>
                  <Typography
                    level="body-lg"
                    fontWeight="lg"
                    textColor="#fff"
                    mt={{ xs: 12, sm: 18 }}
                  >
                    Video
                  </Typography>
                  <Button
                    size="sm"
                    width="5px"
                    fullWidth={false}
                    onClick={() => handleFullScreen(createAskVideoRef)}
                  >
                    Play
                  </Button>
                </CardContent>
              </Card>
            </Card>
            <Card
              component="li"
              sx={{
                display: "flex",
                flexDirection: "column", // Ensure the card content stacks vertically
                minWidth: 300,
                minHeight: "flex",
                direction: "row",
                maxWidth: "75%",
              }}
            >
              <Typography level="h4">Interact With Ask</Typography>
              <Card
                component="li"
                sx={{
                  display: "flex",
                  flexDirection: "column", // Ensure the card content stacks vertically
                  minWidth: 300,
                  minHeight: "flex",
                  direction: "row",
                  maxWidth: "75%",
                }}
              >
                <CardCover>
                  <video
                    ref={markFulfilledVideoRef}
                    muted
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                    onClick={() => handleVideoClick(markFulfilledVideoRef)}
                  >
                    <source src={mark_fulfilled} type="video/mp4" />
                  </video>
                </CardCover>
                <CardContent>
                  <Typography
                    level="body-lg"
                    fontWeight="lg"
                    textColor="#fff"
                    mt={{ xs: 12, sm: 18 }}
                  >
                    Video
                  </Typography>
                  <Button
                    size="sm"
                    width="5px"
                    fullWidth={false}
                    onClick={() => handleFullScreen(markFulfilledVideoRef)}
                  >
                    Play
                  </Button>
                </CardContent>
              </Card>
            </Card>
          </>
        )}
        <Stack>
          <Typography level="body-lg">
            For further questions, contact us at <b>gyftbayalerts@gmail.com</b>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
