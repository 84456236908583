import { React, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Divider,
  FormLabel,
  Stack,
  Typography,
  Card,
  Button,
  Input,
  LinearProgress,
  Modal,
  ModalDialog,
} from "@mui/joy/";
import { ip } from "../ip";

function Profile(props) {
  axios.defaults.withCredentials = true;
  const user = props.user;
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState(false);
  const Success_Message = props.Success_Message;
  const Error_Message = props.Error_Message;

  const validate_change_password = () => {
    if (password === confirmPassword) {
      if (password.length < 8) {
        Error_Message("Password must be at least 8 characters long");
        return false;
      }
      setOpen(true);
      return true;
    } else {
      Error_Message("Passwords do not match");
      return false;
    }
  };

  const change_password = () => {
    axios
      .post(`${ip}/signup/changePassword`, {
        email: user.email,
        password: oldPassword,
        new_password: password,
      })
      .then((response) => {
        if (response.data.message === "Incorrect Password") {
          Error_Message("Current Password is Incorrect");
          setOldPassword("");
          setPassword("");
          setConfirmPassword("");
        } else if (response.data.message === "Password Changed") {
          Success_Message("Password Changed!");
          setOldPassword("");
          setPassword("");
          setConfirmPassword("");
        }
      });
  };
  return (
    <Box sx={{ px: { xs: 2, md: 6 } }}>
      <ToastContainer />
      <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
        My Profile
      </Typography>
      <Stack
        spacing={4}
        sx={{
          display: "flex",
          maxWidth: "800px",
          mx: "auto",
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
        minHeight={900}
      >
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-lg">Personal info</Typography>
          </Box>
          <Divider />
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={1}>
              <FormLabel>
                <b>Name</b>
              </FormLabel>
              <Typography level="body-md">{user["fullName"]}</Typography>
            </Stack>
          </Stack>
          {user["account_type"] === "NPO" ? (
            <>
              <Divider orientation="horizontal" />
              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Stack spacing={1}>
                  <FormLabel>
                    <b>Organization</b>
                  </FormLabel>
                  <Typography level="body-md">
                    {user["organization"]}
                  </Typography>
                </Stack>
              </Stack>
            </>
          ) : null}
          <Divider orientation="horizontal" />
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={1}>
              <FormLabel>
                <b>Email</b>
              </FormLabel>
              <Typography level="body-md">{user["email"]}</Typography>
            </Stack>
          </Stack>
          <Divider orientation="horizontal" />
          <Stack
            sx={{
              "--hue": Math.min(password.length * 12, 120),
            }}
            spacing={1}
          >
            <FormLabel>
              <b>Change Password (Must be at least 8 characters)</b>
            </FormLabel>
            <Input
              type="password"
              placeholder="Old Password"
              sx={{ maxWidth: "400px" }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <Divider />
            <Input
              type="password"
              placeholder="New Password"
              sx={{ maxWidth: "400px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <LinearProgress
              determinate
              size="sm"
              value={Math.min((password.length * 100) / 8, 100)}
              sx={{
                bgcolor: "background.level3",
                color: "hsl(var(--hue) 80% 40%)",
                maxWidth: "400px",
              }}
            />
            <Typography
              level="body-xs"
              sx={{ alignSelf: "center", color: "hsl(var(--hue) 80% 30%)" }}
            >
              {password.length < 3 && "Very weak"}
              {password.length >= 3 && password.length < 6 && "Weak"}
              {password.length >= 6 && password.length < 10 && "Strong"}
              {password.length >= 10 && "Very strong"}
            </Typography>
            <Input
              type="password"
              placeholder="Confirm Password"
              sx={{ maxWidth: "400px" }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              onClick={() => {
                validate_change_password();
              }}
              color="success"
              sx={{ maxWidth: "200px", alignSelf: "end"}}
            >
              Change Password?
            </Button>
            <Modal
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <ModalDialog>
                <Typography
                  sx={{ justifyContent: "center", alignItems: "flex-start" }}
                  level="body-lg"
                >
                  Are you sure you want to change your password?
                </Typography>
                <Stack spacing={2} direction="row" justifyContent={"center"}>
                  <Button
                    color="success"
                    onClick={() => {
                      change_password();
                      setOpen(false);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    No
                  </Button>
                </Stack>
              </ModalDialog>
            </Modal>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
}

export default Profile;
