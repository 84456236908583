import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, Autocomplete, CircularProgress, Stack } from "@mui/joy/";
import { ip } from "../../ip";
import HomeSchool from "../Marketplace/HomeSchool";

function OrgAsks(props) {
  const { user, isAuthenticated, Success_Message, Error_Message, confetti, checkedStates, setCheckedStates, handleCheckBoxChange, clean_data, changeMultipleStatus, addToInventory, statusClick, setStatusClick } = props;
  const [orgUsers, setOrgUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${ip}/user/users/${user.organization}/${user.email}`)
      .then((res) => {
        setOrgUsers(res.data);
        setFilteredUsers(res.data);
      })
      .catch((error) => {
        console.error("Error fetching organization users:", error);
      });
  }, [user.organization, user.email]);

  useEffect(() => {
    // Filter orgUsers based on search query
    if (!search) {
      setFilteredUsers(orgUsers);
      return;
    }
    const filteredUsers = orgUsers.filter((orgUser) =>
      orgUser?.fullName?.toLowerCase().includes(search?.toLowerCase())
    );

    // Update filteredOrgUsers state with the filtered users
    setFilteredUsers(filteredUsers);
  }, [orgUsers, search]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box sx={{ px: { xs: 2, md: 6 } }}>
        <Stack spacing={2}>
          <Typography level="h1">All Asks From Organization</Typography>
          <Autocomplete
            id="combo-box-demo"
            options={orgUsers}
            getOptionLabel={(option) => option.fullName}
            value={orgUsers.find((user) => user.fullName === search)}
            style={{ width: 300 }}
            placeholder="Search By Navigator"
            onChange={(e, value) => {
              //show loading bar for 1/2 a second before searching
              setLoading(true);
              setSearch(value?.fullName);
              setTimeout(() => {
                setLoading(false);
              }, 250);
            }}
          />
        </Stack>
      </Box>
      {filteredUsers.map((orgUser, index) => (
        <>
          {search ? (
            <Typography key={index} level="h3" sx={{ px: { xs: 2, md: 6 } }}>
              Navigator: {orgUser.fullName}
            </Typography>
          ) : (
            <Typography key={index} level="h3" sx={{ px: { xs: 2, md: 6 } }}>
              Navigator #{index + 1}: {orgUser.fullName}
            </Typography>
          )}
          <HomeSchool
            user={orgUser}
            admin={true}
            isAuthenticated={isAuthenticated}
            Success_Message={Success_Message}
            Error_Message={Error_Message}
            confetti={confetti}
            checkedStates={checkedStates}
            setCheckedStates={setCheckedStates}
            handleCheckBoxChange={handleCheckBoxChange}
            clean_data={clean_data}
            changeMultipleStatus={changeMultipleStatus}
            addToInventory={addToInventory}
            statusClick={statusClick}
            setStatusClick={setStatusClick}
          />
        </>
      ))}
    </>
  );
}

export default OrgAsks;
