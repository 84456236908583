import { React } from 'react'
import '../style/nav.css';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios'
import { Dropdown, MenuButton, Menu, MenuItem, Stack } from '@mui/joy';
import { ip } from '../ip';
import healthhub from '../images/healthhub.png';
import logo from '../images/logo.png';


function Nav(props) {
    axios.defaults.withCredentials = true;
    const user = props.user;
    const setUser = props.setUser;
    const setIsAuthenticated = props.setIsAuthenticated;
    const navigate = useNavigate();

    function Logout() {
        if (user) {
          axios
            .delete(`${ip}/logout`, { withCredentials: true })
            .then((response) => {
              setUser({});
              setIsAuthenticated(false);
            });
          navigate("/", { state: { loggedOut: true } });
        }
    }

    const navStyle = {
      height: "50px", // Increase the height of the nav bar
      padding: "0 20px", // Add padding to the nav bar
      alignItems: "center", // Center items vertically
    };

    const menuButtonStyle = {
      padding: "12px 24px", // Increase padding inside buttons
      fontSize: "18px", // Increase font size
      height: "40px" // Make buttons taller
    };

    return (
      <Stack justifyContent={"flex-end"}>
        {user && user["id"] ? (
          <div>
            {user["account_type"] === "NPO" ||
            user["account_type"] === "member" ? (
              // NPO or Member
              <nav className="nav" style={navStyle}>
                <ul>
                  {/* Existing menu items for NPO/Member */}
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      onClick={() => navigate("/landingPage")}
                      style={menuButtonStyle}
                    >
                      Landing Page
                    </MenuButton>
                  </Dropdown>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      Home
                    </MenuButton>
                    <Menu>
                      <MenuItem onClick={() => navigate("/home")}>
                        All Asks
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/accepted")}>
                        Status Of Asks
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </ul>
                <ul style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      My Account
                    </MenuButton>
                    <Menu>
                      <MenuItem onClick={() => navigate("/profile")}>
                        Profile
                      </MenuItem>
                      <MenuItem color="danger" onClick={Logout}>
                        Logout
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      Help
                    </MenuButton>
                    <Menu>
                      <MenuItem onClick={() => navigate("/training")}>
                        Training
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </ul>
              </nav>
            ) : user["account_type"] === "Admin" ? (
              // Admin
              <nav className="nav" style={navStyle}>
                <ul>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      onClick={() => navigate("/landingPage")}
                      style={menuButtonStyle}
                    >
                      Landing Page
                    </MenuButton>
                  </Dropdown>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      Home
                    </MenuButton>
                    <Menu>
                      <MenuItem onClick={() => navigate("/home")}>
                        My Asks
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/create")}>
                        Create Ask
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/orgAsks")}>
                        Organization Asks
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      Inventory
                    </MenuButton>
                    <Menu>
                      <MenuItem onClick={() => navigate("/inventory")}>
                        Inventory
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/addInventory")}>
                        Add Inventory
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </ul>
                <ul style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      My Account
                    </MenuButton>
                    <Menu>
                      <MenuItem onClick={() => navigate("/profile")}>
                        Profile
                      </MenuItem>
                      <MenuItem color="danger" onClick={Logout}>
                        Logout
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      Help
                    </MenuButton>
                  </Dropdown>
                </ul>
              </nav>
            ) : (
              // School/Navigator
              <nav className="nav" style={navStyle}>
                <ul>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      onClick={() => navigate("/landingPage")}
                      style={menuButtonStyle}
                    >
                      Landing Page
                    </MenuButton>
                  </Dropdown>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      Home
                    </MenuButton>
                    <Menu>
                      <MenuItem onClick={() => navigate("/home")}>
                        My Asks
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/create")}>
                        Create Ask
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      Inventory
                    </MenuButton>
                    <Menu>
                      <MenuItem onClick={() => navigate("/inventory")}>
                        Inventory
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/addInventory")}>
                        Add Inventory
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </ul>
                <ul style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      My Account
                    </MenuButton>
                    <Menu>
                      <MenuItem onClick={() => navigate("/profile")}>
                        Profile
                      </MenuItem>
                      <MenuItem color="danger" onClick={Logout}>
                        Logout
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                  <Dropdown>
                    <MenuButton
                      variant="solid"
                      color="primary"
                      style={menuButtonStyle}
                    >
                      Help
                    </MenuButton>
                    <Menu>
                      <MenuItem onClick={() => navigate("/training")}>
                        Training
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </ul>
              </nav>
            )}
          </div>
        ) : (
          // Non-logged-in users
          <div>
            <nav
              className="nav"
              style={ {height: "50px", padding: "0 20px", alignItems: "center", justifyContent: "flex-end"} }
            >
              <ul style={{ display: "flex", justifyContent: "flex-end" }}>
                <Dropdown>
                  <MenuButton
                    variant="solid"
                    color="primary"
                    onClick={() => navigate("/browse")}
                    style={menuButtonStyle}
                  >
                    Browse
                  </MenuButton>
                </Dropdown>
                <Dropdown>
                  <MenuButton
                    variant="solid"
                    color="primary"
                    onClick={() => navigate("/login")}
                    style={menuButtonStyle}
                  >
                    Login
                  </MenuButton>
                </Dropdown>
                {/* <Dropdown>
                  <MenuButton
                    variant="solid"
                    color="primary"
                    onClick={() => navigate("/signup")}
                    style={menuButtonStyle}
                  >
                    Signup
                  </MenuButton>
                </Dropdown> */}
                <Dropdown>
                  <MenuButton
                    variant="solid"
                    color="primary"
                    onClick={() => navigate("/contact")}
                    style={menuButtonStyle}
                  >
                    Contact
                  </MenuButton>
                </Dropdown>
              </ul>
            </nav>
          </div>
        )}
      </Stack>
    );
}



export default Nav