import React from "react";
import HomeSchool from "./Marketplace/HomeSchool";
import HomeNPO from "./Marketplace/HomeNPO";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import { Card, AspectRatio, Button, Input, FormLabel, FormControl, Typography, Sheet, Box, Stack } from "@mui/joy";
import healthhub from "../images/healthhub.png";
import { ip } from "../ip.js";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Entry(props) {
  const user = props.user;
  const setUser = props.setUser;
  const isAuthenticated = props.isAuthenticated;
  const setIsAuthenticated = props.setIsAuthenticated;
  const showLoggedOut = props.showLoggedOut;
  const navigate = useNavigate();

  useEffect(() => {
    
    showLoggedOut();

  }, []);



  return (
    <CssVarsProvider>
      <AspectRatio objectFit="contain" maxHeight="200px">
        <img src={healthhub} loading="lazy" alt="" />
      </AspectRatio>
      <Box
        sx={{
          minHeight: 700,
          alignContent: "center",

          display: "flex",
          flexDirection: "column", // Set the flexDirection to 'row'
          gap: 2,
          overflowX: "auto", // Add this to enable horizontal scrolling if needed
          alignItems: "center", // Center the child elements vertically
        }}
      >
        <ToastContainer />
        <Stack spacing={2} direction={"column"} alignItems={"center"}>
          <Typography level="h1">Welcome To GyftBay!</Typography>
          <Card
            variant="outlined"
            sx={(theme) => ({
              alignContent: "center",
              gridColumn: "span 2",
              flexDirection: "row",
              flexWrap: "wrap",
              overflow: "auto",
              gap: "clamp(0px, (100% - 360px + 32px) * 999, 16px)",
              transition: "transform 0.3s, border 0.3s",
              "&:hover": {
                borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                transform: "translateY(-2px)",
              },
              ":hover": {
                cursor: "pointer",
              },
              "& > *": { minWidth: "clamp(0px, (360px - 100%) * 999,100%)" },
            })}
            onClick={() => {
              navigate("/browse");
            }}
          >
            <Typography level="title-lg">Browse</Typography>
          </Card>
          <Card
            variant="outlined"
            sx={(theme) => ({
              alignContent: "center",
              gridColumn: "span 2",
              flexDirection: "row",
              flexWrap: "wrap",
              overflow: "auto",
              gap: "clamp(0px, (100% - 360px + 32px) * 999, 16px)",
              transition: "transform 0.3s, border 0.3s",
              "&:hover": {
                borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                transform: "translateY(-2px)",
              },
              ":hover": {
                cursor: "pointer",
              },
              "& > *": { minWidth: "clamp(0px, (360px - 100%) * 999,100%)" },
            })}
            onClick={() => {
              navigate("/login");
            }}
          >
            <Typography level="title-lg">Login</Typography>
          </Card>
          <Card
            variant="outlined"
            sx={(theme) => ({
              alignContent: "center",
              gridColumn: "span 2",
              flexDirection: "row",
              flexWrap: "wrap",
              overflow: "auto",
              gap: "clamp(0px, (100% - 360px + 32px) * 999, 16px)",
              transition: "transform 0.3s, border 0.3s",
              "&:hover": {
                borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                transform: "translateY(-2px)",
              },
              ":hover": {
                cursor: "pointer",
              },
              "& > *": { minWidth: "clamp(0px, (360px - 100%) * 999,100%)" },
            })}
            onClick={() => {
              navigate("/contact");
            }}
          >
            <Typography level="title-lg">Contact</Typography>
          </Card>
        </Stack>
      </Box>
    </CssVarsProvider>
  );
}

export default Entry;
