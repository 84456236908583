import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Stack,
  Box,
  Card,
  Typography,
  Button,
  ModalDialog,
  Modal,
  Option,
  Input,
  Select,
  Autocomplete,
  Table,
  Sheet,
} from "@mui/joy/";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { ip } from "../../ip";
import { Divider } from "@mui/material";

function AddInventory(props) {
  axios.defaults.withCredentials = true;
  const user = props.user;
  const [statusClick, setStatusClick] = useState(false);
  const Success_Message = props.Success_Message;
  const Error_Message = props.Error_Message;
  const [open, setOpen] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [location, setLocation] = useState("");
  const [dateReceived, setDateReceived] = useState("");
  const [products, setProducts] = useState({});
  const [suppliers, setSuppliers] = useState({});
  const [healthHubs, setHealthHubs] = useState({});
  const [error, setError] = useState(null);
  const [data, setData] = useState([
    { Item: null, Quantity: "", Supplier: null },
  ]);

  useEffect(() => {
    axios.get(`${ip}/user/npo`).then((response) => {
      const new_suppliers = [{ id: 0, organization: "N/A" }, ...response.data];
      setSuppliers(new_suppliers);
    });

    axios.get(`${ip}/products`).then((response) => {
      setProducts(response.data);
    });
    setHealthHubs([
      { name: "FACE", address: "16164 Asbury Park Detroit, MI 48235" },
      { name: "Maybury", address: "4410 Porter Street Detroit, MI 48209" },
      { name: "Central", address: "2425 Tuxedo Street Detroit, MI 48206" },
      {
        name: "Southeastern",
        address: "3030 Fairview Street Detroit, MI 48214",
      },
      { name: "EEVPA", address: "5020 Cadieux Road Detroit, MI 48224" },
      { name: "Osborn", address: "11600 East 7 Mile Road Detroit, MI 48205" },
      { name: "Marygrove", address: "8425 West McNichols Detroit, MI 48221" },
    ]);
  }, []);

  const validate = () => {
    //check data, location, and date received
    if (location === "" || dateReceived === "") {
      Error_Message("Please fill out all fields");
      if (location === "") {
        setError("location");
      }
      if (dateReceived === "") {
        setError("dateReceived");
      }
      return false;
    }
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].Item === null ||
        data[i].Quantity === "" ||
        data[i].Supplier === null
      ) {
        Error_Message("Please fill out all fields");
        if (data[i].Item === null) {
          setError({ Item: i });
        }
        if (data[i].Quantity === "") {
          setError({ Quantity: i });
        }
        if (data[i].Supplier === null) {
          setError({ Supplier: i });
        }
        return false;
      }
    }
    return true;
  };

  const addInventory = () => {
    if (!validate()) {
      return;
    }
    axios
      .post(`${ip}/inventory/add`, {
        address:
          healthHubs[
            Object.keys(healthHubs).find(
              (key) => healthHubs[key].name === location
            )
          ].address,
        dateReceived: dateReceived,
        data: data,
        organization: user.organization,
      })
      .then((response) => {
        setData([{ Item: null, Quantity: "", Supplier: null }]);
        setLocation("");
        setDateReceived("");
        setOpen(false);
        Success_Message("Added Inventory Successfully!");
      });
  };

  console.log("error", error);

  return (
    <Box paddingBottom={0} borderBottom={0} minHeight={750} paddingLeft={5}>
      <Card sx={{ maxWidth: 1000 }}>
        <ToastContainer />
        <Stack spacing={1}>
          <Typography level="h1" textAlign={"left"} justifyContent={"center"}>
            Add Inventory
          </Typography>
          <Typography level="body-lg" align="left">
            Input Received Inventory
          </Typography>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          minHeight={"flex"}
        >
          <Card sx={{ p: 2, maxWidth: 1000 }}>
            <Stack direction="row" spacing={2}>
              <Typography>HealthHub:</Typography>
              <Autocomplete
                placeholder="HealthHub"
                options={Object.keys(healthHubs).map(
                  (hub) => healthHubs[hub].name
                )}
                value={location}
                onChange={(e, value) => {
                  setLocation(value);
                  if (value !== "") {
                    setError(null);
                  }
                }}
                isOptionEqualToValue={(option, value) => option === value}
                error={error === "location"}
              />
              <Typography>Address:</Typography>
              <Input
                placeholder="address"
                value={
                  location
                    ? healthHubs[
                        Object.keys(healthHubs).find(
                          (key) => healthHubs[key].name === location
                        )
                      ].address
                    : ""
                }
                sx={{ width: "auto" }}
              />
              <Typography>Date Received:</Typography>
              <Input
                type="date"
                value={dateReceived}
                onChange={(e) => {
                  setDateReceived(e.target.value);
                  if (e.target.value !== "") {
                    setError(null);
                  }
                }}
                error={error === "dateReceived"}
              />
            </Stack>
            <Sheet sx={{ minWidth: 750, alignItems: "flex-end" }}>
              <Table
                aria-label="basic table"
                variant="outlined"
                borderAxis="xBetween"
                sx={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Supplier</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((set, index) => (
                    <tr className="textboxes" key={index}>
                      <td>
                        <Autocomplete
                          placeholder="Product Name"
                          options={Object.keys(products).map(
                            (productId) => products[productId].productName
                          )}
                          value={set.Item}
                          onChange={(e, value) => {
                            let newData = [...data];
                            newData[index].Item = value;
                            setData(newData);
                            if (value !== "") {
                              setError(null);
                            }
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          error={error && error.Item === index}
                        />
                      </td>
                      <td>
                        <Input
                          placeholder="Quantity"
                          value={set.Quantity}
                          onChange={(e) => {
                            let newData = [...data];
                            newData[index].Quantity = e.target.value;
                            setData(newData);
                            if (e.target.value !== "") {
                              setError(null);
                            }
                          }}
                          error={error && error.Quantity === index}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          value={set.Supplier}
                          onChange={(e, res) => {
                            let newData = [...data];
                            newData[index].Supplier = res;
                            setData(newData);
                            if (res !== "") {
                              setError(null);
                            }
                          }}
                          placeholder="Supplier"
                          //options are the suppliers and N/A
                          options={Object.keys(suppliers).map(
                            (supplierId) => suppliers[supplierId].organization
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          error={error && error.Supplier === index}
                        />
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            let newData = [...data];
                            newData.splice(index, 1);
                            setData(newData);
                          }}
                          color="danger"
                        >
                          <DeleteIcon />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Sheet>
            <Stack direction={"row"} spacing={2}>
              <Button
                onClick={() => {
                  setData([...data, { Item: "", Quantity: "", Supplier: "" }]);
                }}
                sx={{ mt: 2, width: 200 }}
              >
                <AddCircleIcon /> Add Item
              </Button>
            </Stack>
            <Button
              sx={{ mt: 2, width: 200, alignSelf: "end" }}
              color="success"
              onClick={() => {
                setOpen(true);
              }}
            >
              Done!
            </Button>
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <ModalDialog
                sx={{ p: 2, width: "auto" }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Typography>
                  Are you sure you want to submit this inventory?
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    onClick={() => {
                      addInventory();
                    }}
                    sx={{ mt: 2, width: 200 }}
                    color="success"
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    color="danger"
                    sx={{ mt: 2, width: 200 }}
                  >
                    No
                  </Button>
                </Stack>
              </ModalDialog>
            </Modal>
          </Card>
        </Stack>
      </Card>
    </Box>
  );
}

export default AddInventory;
